
import { defineComponent, reactive, ref, Ref, onMounted, inject } from "vue";
import { useI18n } from "vue-i18n";
import moment from "moment";
import {
  MoreOutlined,
  ExclamationCircleFilled,
  WarningOutlined,
} from "@ant-design/icons-vue";

import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import {
  Visit,
  ResponseList,
  FinalVisitStatus,
  VisitStatus,
  VisitExt,
  VisitsFilterData,
  ChooseVisitModal,
  Call,
} from "../../types";
import { TableState } from "ant-design-vue/lib/table/interface";
import { SorterType } from "../../types/vue-antd";
import ChangeStatus from "../components/ChangeStatus.vue";
import { notification, Badge } from "ant-design-vue";
import VisitsFilters from "../components/VisitsFilters.vue";
import { visitTypesIconMap } from "../utils/const";
import ChooseVisit from "../components/ChooseVisit.vue";
import { useRouter, useRoute } from "vue-router";
import { CheckUserAgent } from "@hd2/common/src/utils";
import { AxiosStatic } from "axios";
import { useAurero } from "../composable/useAurero";

interface Table {
  columns: TableState["columns"];
  sorter: SorterType;
  data: Array<VisitExt>;
  pagination: Omit<TableState["pagination"], "current,total,pageSize"> & {
    current: number;
    total: number;
    pageSize: number;
  };
  loading: boolean;
}

interface ChangeStatusModal {
  visible: boolean;
  visit: VisitExt;
  afterSuccess: VoidFunction;
  afterCancel: VoidFunction;
}

export const VisitsComponent = defineComponent({
  components: {
    MoreOutlined,
    ChangeStatus,
    VisitsFilters,
    ChooseVisit,
    ExclamationCircleFilled,
    Badge,
    WarningOutlined,
  },
  setup() {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const { showErrors } = useShowErrors();
    const router = useRouter();
    const route = useRoute();
    const isSupported =
      !CheckUserAgent.isSafari() && !CheckUserAgent.isOperaMobile();
    const { getVisitUrl } = useAurero();

    let filters: VisitsFilterData = {
      date: moment(),
      pesel: "",
      visitTypes: [],
      statuses: ["PAID"],
    };
    const page: number = route.query.page ? Number(route.query.page) : 1;
    const pageSize: number = route.query.pageSize
      ? Number(route.query.pageSize)
      : 10;

    const visitsFilters: Ref<typeof VisitsFilters> = ref(VisitsFilters);

    const visitFinalStatuesLoading: Ref<boolean> = ref(false);
    const finalVisitStatuses: Ref<Array<VisitStatus>> = ref([]);

    const table: Table = reactive({
      data: [],
      columns: [
        {
          title: t("VISITS.TABLE.APPOINTMENT_TIME"),
          dataIndex: "from",
          sorter: false,
          slots: { customRender: "appointmentTime" },
        },
        {
          title: t("VISITS.TABLE.PATIENT_DATA"),
          dataIndex: "patient",
          sorter: false,
          slots: { customRender: "patient" },
        },
        {
          title: t("VISITS.TABLE.PREFERRED_VISIT_LANGUAGE"),
          dataIndex: "preferredVisitLanguage",
          sorter: false,
          slots: { customRender: "preferredVisitLanguage" },
        },
        {
          title: t("VISITS.TABLE.CONTRACTOR"),
          dataIndex: "contractor",
          sorter: false,
          slots: { customRender: "contractor" },
        },
        {
          title: t("VISITS.TABLE.NFZ"),
          sorter: false,
          slots: { customRender: "nfz" },
        },
        {
          title: t("VISITS.TABLE.VISIT_DATA"),
          dataIndex: "details",
          sorter: false,
          slots: { customRender: "details" },
        },
        {
          title: t("VISITS.TABLE.PAYMENT"),
          dataIndex: "payment",
          sorter: false,
          slots: { customRender: "payment" },
        },
        {
          title: t("VISITS.TABLE.STATUS"),
          dataIndex: "status",
          sorter: false,
          slots: { customRender: "status" },
        },
        {
          dataIndex: "startVisit",
          sorter: false,
          slots: { customRender: "startVisit" },
        },
      ],
      sorter: {},
      loading: true,
      pagination: {
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "25", "50"],
        buildOptionText: ({ value }: { value: string }) => {
          return value;
        },
        showTotal: (total: number, range: Array<number>) =>
          t("PAGINATION", {
            total,
            rangeFrom: range[0],
            rangeTo: range[1],
          }),
        current: page,
        pageSize: pageSize,
        total: 0,
      },
    });

    const chooseVisitModal: ChooseVisitModal = reactive({
      visible: false,
      data: undefined,
      paymentType: "",
      redirectUrl: "",
    });

    const startVisit = async (record: VisitExt) => {
      if (
        record.patients.length > 1 ||
        record.details.type === "HOUSE" ||
        (record.patients[0].symptoms
          ? record.patients[0].symptoms.length > 0
          : false) ||
        (record.countryOfResidence
          ? record.countryOfResidence != "POL"
          : false) ||
        (record.countryOfResidence
          ? record.countryOfResidence.length === 0
          : false)
      ) {
        chooseVisitModal.data = record;
        chooseVisitModal.visible = true;
        chooseVisitModal.paymentType = record.payment.type;
        chooseVisitModal.redirectUrl = `?redirectUrl=${route.path}&page=${table.pagination.current}&pageSize=${table.pagination.pageSize}&choosedVisitId=${record.id}`;
      } else {
        if (isSupported) {
          router.push(
            `/visit/${record.patients[0].aureroVisitId}?redirectUrl=${route.path}&page=${table.pagination.current}&pageSize=${table.pagination.pageSize}&paymentType=${record.payment.type}&choosedVisitId=${record.id}`
          );
        } else {
          const windowReference = window.open("#");
          await getVisitUrl(record.patients[0].aureroVisitId.toString()).then(
            (url: string) => {
              windowReference?.location.assign(url);
            }
          );

          const call = ref<Call>();
          call.value = await http
            .get(`v1/doctors/telephone/${record.patients[0].aureroVisitId}`)
            .then((res) => res.data);

          if (call.value?.enabled) {
            const callIFrameUrl = `/call.html?backUrl=${btoa(
              window.location.href
            )}&sip=${call.value?.sip}&login=${call.value?.login}&wssUrl=${
              call.value?.wssUrl
            }&callTo=${call.value?.patientPhone}&password=${btoa(
              call.value?.password ? call.value.password : ""
            )}`;

            window.location.href = callIFrameUrl;
          }
        }
      }
    };

    const getFinalVisitStatuses = async () => {
      visitFinalStatuesLoading.value = true;
      try {
        finalVisitStatuses.value = await http
          .get("v1/visits/final-statuses")
          .then((res): Array<FinalVisitStatus> => res.data);
      } catch (err: any) {
        showErrors(err.response?.data);
      } finally {
        visitFinalStatuesLoading.value = false;
      }
    };
    const getVisits = async (
      pagination: TableState["pagination"],
      filters: VisitsFilterData
    ) => {
      table.loading = true;
      const link = `v1/visits?page=${
        pagination?.current ? pagination?.current - 1 : 0
      }&size=${pagination?.pageSize}${
        filters.date ? `&date=${filters.date?.format("YYYY-MM-DD")}` : ""
      }&pesel=${filters.pesel}&visitType=${filters.visitTypes.join(
        ","
      )}&statuses=${filters.statuses.toString()}&sort=date,ASC`;

      try {
        const visitsRes = await http
          .get(link)
          .then((res): ResponseList<Visit> => res.data);
        table.data = visitsRes.content.map((visit: Visit) => {
          return {
            ...visit,
            tmpStatus: visit.status,
            isChangeStatusDisabled:
              visit.details.type == "STATIONARY" ||
              visit.details.type == "HOUSE"
                ? false
                : moment() > moment(visit.from).subtract(1, "hours")
                ? false
                : true,
            details: {
              ...visit.details,
            },
          };
        });
        table.pagination = {
          current: visitsRes.pageable.pageNumber + 1,
          total: visitsRes.totalElements,
          pageSize: visitsRes.size,
        };
      } catch (err: any) {
        showErrors(err.response?.data);
      } finally {
        table.loading = false;
        if (route.query.choosedVisitId) {
          const record: VisitExt | undefined = table.data.find(
            (elem) => elem.id === Number(route.query.choosedVisitId)
          );
          if (record && record.details.type == "HOUSE") {
            startVisit(record);
          }
        }
      }
    };

    onMounted(() => {
      getVisits(table.pagination, filters);
      getFinalVisitStatuses();
    });

    const showPaymentStatus = (status: string, type: string) => {
      if (
        type.includes("CONTRACT") ||
        type.includes("PAID") ||
        status === "ACCEPTED"
      ) {
        return t(`PAYMENT_TYPE.PAID`);
      } else if (type === "CASH") {
        return t(`PAYMENT_TYPE.CASH`);
      } else if (status === "REJECTED") {
        return t(`PAYMENT_TYPE.${type}`);
      }
    };

    const isStartVisitDisabled = (visit: VisitExt) => {
      let disabled = false;
      switch (visit.details.type) {
        case "VIDEO":
        case "CALL":
        case "CHAT": {
          disabled = moment(visit.from) >= moment().add(1, "hours");
          break;
        }
        default: {
          disabled = false;
        }
      }
      return disabled;
    };

    const handleTableChange = (pagination: TableState["pagination"]) => {
      router.replace(
        `${route.path}?page=${pagination?.current}&pageSize=${pagination?.pageSize}`
      );
      getVisits(pagination, filters);
    };

    const changeStatusModal: ChangeStatusModal = reactive({
      visible: false,
      visit: {
        id: 0,
        status: "CANCELLED_BY_DOCTOR",
        tmpStatus: "CANCELLED_BY_DOCTOR",
        from: "",
        to: "",
        isChangeStatusDisabled: true,
        preferredVisitLanguage: "pol",
        countryOfResidence: "POL",
        patients: [
          {
            id: 0,
            aureroVisitId: 0,
            firstName: "",
            lastName: "",
            pesel: "",
            phone: "",
            aureroVisitStatus: "INIT",
            symptoms: "",
          },
        ],
        details: {
          contractorName: "",
          type: "VIDEO",
          specialization: "ONCOLOGIC",
          nfzVisit: false,
          nfzProductCode: "",
        },
        payment: {
          patientPrice: 0,
          type: "ONLINE",
          status: "NOT_PAID",
        },
      },
      afterSuccess: () => {
        getVisits(table.pagination, filters);
        changeStatusModal.visible = false;
      },
      afterCancel: () => {
        changeStatusModal.visit.tmpStatus = changeStatusModal.visit.status;
        changeStatusModal.visible = false;
      },
    });

    const onVisitsFilterSubmit = (value: VisitsFilterData) => {
      filters = value;
      table.pagination.current = 1;
      getVisits(table.pagination, filters);
    };

    const onChangeVisitStatus = async (visit: VisitExt) => {
      switch (visit.tmpStatus) {
        case "CANCELLED_BY_DOCTOR": {
          changeStatusModal.visit = visit;
          changeStatusModal.visible = true;
          break;
        }
        default: {
          try {
            await http.put(`v1/visits/${visit.id}/status`, {
              status: visit.tmpStatus,
            });
            notification.open({
              message: t("CHANGE_STATUS.SUCCESS"),
              class: "success",
            });
            await getVisits(table.pagination, filters);
          } catch (err: any) {
            visit.tmpStatus = visit.status;
            showErrors(err.response?.data);
          }
        }
      }
    };

    return {
      t,
      finalVisitStatuses,
      onChangeVisitStatus,
      table,
      visitsFilters,
      visitFinalStatuesLoading,
      onVisitsFilterSubmit,
      handleTableChange,
      isStartVisitDisabled,
      startVisit,
      changeStatusModal,
      filters,
      visitTypesIconMap,
      showPaymentStatus,
      chooseVisitModal,
      page,
      pageSize,
      isSupported,
    };
  },
});
export default VisitsComponent;
